// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PostSetup_section_1rxuj{display:grid;background:#f8f8f8}", ""]);
// Exports
exports.locals = {
	"section": "PostSetup_section_1rxuj"
};
module.exports = exports;
