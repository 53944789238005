// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VToggle_toggle_NLLTy{position:relative;align-self:center;width:38px;height:18px}.VToggle_toggle_NLLTy.VToggle_big_2B5cz{width:72px;height:36px}.VToggle_toggle_NLLTy.VToggle_big_2B5cz .VToggle_knobs_aAJp2:before{width:22px;height:22px;top:7px;left:7px}.VToggle_toggle_NLLTy.VToggle_big_2B5cz input:checked+.VToggle_knobs_aAJp2:before{left:calc(100% - 29px)}.VToggle_input_6irdc{position:relative;z-index:3;width:100%;height:100%;margin:0;padding:0;cursor:pointer;opacity:0}.VToggle_input_6irdc:disabled{cursor:not-allowed}.VToggle_input_6irdc:active+.VToggle_knobs_aAJp2:before{width:45%;border-radius:100px}.VToggle_input_6irdc:checked:active+.VToggle_knobs_aAJp2:before{margin-left:-15%}.VToggle_input_6irdc:checked+.VToggle_knobs_aAJp2:before{left:calc(100% - 15px);opacity:1;content:\"\"}.VToggle_knobs_aAJp2,.VToggle_layer_20Jsa{position:absolute;top:0;right:0;bottom:0;left:0}.VToggle_layer_20Jsa{z-index:1;width:100%;background:#f8f8f8;border:1px solid #e5e3e3;border-radius:100px;transition:all .3s ease}.VToggle_knobs_aAJp2{z-index:2}.VToggle_knobs_aAJp2:before{position:absolute;top:3px;left:3px;width:12px;height:12px;border-radius:100%;opacity:.3;transition:all .2s ease,left .45s,cubic-bezier(.18,.89,.35,1.15);content:\"\"}.VToggle_knobs_aAJp2.VToggle_default_1NrTv:before{background:var(--supster-secondary)}.VToggle_knobs_aAJp2.VToggle_primary_1mCSZ:before{background:var(--supster-primary-light-background)}", ""]);
// Exports
exports.locals = {
	"toggle": "VToggle_toggle_NLLTy",
	"big": "VToggle_big_2B5cz",
	"knobs": "VToggle_knobs_aAJp2",
	"input": "VToggle_input_6irdc",
	"layer": "VToggle_layer_20Jsa",
	"default": "VToggle_default_1NrTv",
	"primary": "VToggle_primary_1mCSZ"
};
module.exports = exports;
