<template>
  <ul :class="$style.container">
    <li>
      <toggle-input
        :class="$style.toggle"
        :text="settings.name"
        :active="settings.enable"
        v-model="settings.enable"
        @input="$emit('toogleHandler', settings.enable)"
      />
      <v-grid
        :class="$style['promolist-items']"
        :length="promotionalList.length"
        @infinite="$emit('getPromoPages', $event)"
        v-if="settings.enable"
      >
        <toggle-input
          v-for="({ name, id }, index) of promotionalList"
          :key="index"
          :class="[
            $style.toggle,
            $style['promolist-item'],
            value.promoPageId === id ? $style['promolist-item-active'] : '',
          ]"
          :text="name || `${$t('promoPage')} #${id}`"
          :value="id"
          :active="value.promoPageId === id"
          @input="$emit('input', { ...value, [settings.key]: id })"
        />
      </v-grid>
    </li>
  </ul>
</template>

<script>
import Vue from 'vue'
import ToggleInput from '@elements/inputs/list/ToggleInput.vue'
import VGrid from '@layouts/VGrid.vue'

export default Vue.extend({
  name: 'PostSetupPromotional',
  components: { ToggleInput, VGrid },
  props: {
    value: Object,
    promotionalList: Array,
  },
  data() {
    return {
      settings: {
        name: this.$t('hideSubscription'),
        key: 'promoPageId',
        disabled: false,
        enable: false,
      },
    }
  },
  watch: {
    'value.promoPage': {
      handler(isPromoPage) {
        this.settings.enable = isPromoPage
      },
    },
  },

  mounted() {
    this.settings.enable = this.value.promoPage
  },
})
</script>

<style lang="scss" module>
.container {
  background: white;
}

.promolist {
  &-items {
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
  }
  &-item {
    padding-left: 21px !important;
    &-active {
      pointer-events: none;
    }
  }
}

.toggle {
  padding: 17px 15px;
  box-shadow: inset 0 -1px 0 $secondary-light;
}
</style>
