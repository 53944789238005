<template>
  <div :class="$style.section">
    <post-setup-header v-model="formData" :is-edit="isEdit" @refresh="isError = false" />
    <post-setup-settings v-model="formData" />
    <post-setup-promotional
      v-model="formData"
      :promotionalList="promotionalList"
      @toogleHandler="toogleHandler"
      @getPromoPages="getPromoPages"
    />
    <post-setup-socials />
    <overlay-content-loader :is-loading="pageLoading" :delay="0" />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import OverlayContentLoader from '@loaders/list/OverlayContentLoader.vue'
import PostSetupHeader from './list/PostSetupHeader.vue'
import PostSetupSettings from './list/PostSetupSettings.vue'
import PostSetupPromotional from './list/PostSetupPromotional.vue'
import PostSetupSocials from './list/PostSetupSocials.vue'

export default Vue.extend({
  name: 'PostSetup',
  components: {
    PostSetupHeader,
    PostSetupSocials,
    PostSetupSettings,
    PostSetupPromotional,
    OverlayContentLoader,
  },
  data() {
    return {
      formData: {
        body: '',
        comments: true,
        promoPage: false,
        promoPageId: null,
      },
      isError: false,
    }
  },
  computed: {
    ...mapGetters('App', ['appId']),
    ...mapGetters('Media', ['media']),
    ...mapGetters('Files', ['filesData', 'everyFilesComplete']),
    ...mapGetters('Files', ['filesValid']),
    ...mapGetters('Page', ['pageLoading']),
    ...mapGetters('Post', ['post']),
    ...mapGetters('Promotional', ['promotionalList']),
    postRouteId() {
      return this.$route?.params?.id
    },
    isEdit() {
      return !!this.postRouteId
    },
  },
  methods: {
    ...mapMutations('Media', ['setMedia']),
    ...mapMutations('Files', ['resetFiles', 'setFileProgress']),
    ...mapMutations('Page', ['setPageIsFooter', 'setPageTitle']),
    ...mapActions('Post', ['getPost', 'postPost', 'putPost']),
    ...mapActions('Media', ['postMedia']),
    ...mapActions('Promotional', ['getPromotionalList']),
    toogleHandler(isToolge) {
      this.formData.promoPage = isToolge
    },
    getPromoPages(scroll) {
      this.getPromotionalList({ app_id: this.appId(), limit: 20, scroll })
    },

    async onButton() {
      if (this.formData.body.length >= 3000) {
        this.$notify({
          type: 'warn',
          title: `${this.$t('warnings.title')}`,
          text: `${this.$t('warnings.size')}`,
        })
        return
      }
      if (!this.isEdit) {
        await this.postMedia(this.setFileProgress)
      }
      await this.onSendPost()
    },
    async onSendPost() {
      let params = {
        app_id: this.appId(),
        body: this.formData.body.trim(),
        comments_enabled: this.formData.comments ? 1 : 0,
        promotional_page_id: this.formData.promoPage ? this.formData.promoPageId : null,
      }
      if (!this.isEdit) {
        params = { ...params, media_objects: [this.media[0].id] }
      }
      const post = this.isEdit
        ? await this.putPost({ id: this.postRouteId, params })
        : await this.postPost(params)
      if (post.id) {
        this.$router.go(-1)
      }
    },
  },

  async created() {
    if (!this.filesData && !this.isEdit) {
      this.$router.go(-1)
    }

    if (!this.post && this.isEdit) {
      const post = await this.getPost(this.postRouteId)
      if (!post.id) {
        this.$router.go(-1)
      }
    }

    if (this.post?.promotional_page_id && this.isEdit) {
      this.toogleHandler(true)
      this.formData.promoPageId = this.post.promotional_page_id
    }

    this.$bus.$on('header-send', this.onButton)
    this.setPageIsFooter(false)
    this.setPageTitle({ name: this.isEdit ? 'pages.editPost' : 'pages.newPost' })

    if (this.isEdit) {
      this.formData.body = this.post?.body ?? ''
      this.formData.comments = this.post.comments_enabled === 1
    }
  },
  destroyed() {
    this.$bus.$off('header-send', this.onButton)
    this.setPageIsFooter(true)
    this.resetFiles()
    this.setMedia(null)
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  background: $shadow;
}
</style>
