// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PostSetupSocials_container_ywaA_{display:grid}.PostSetupSocials_title_38rQQ{padding:17px 15px;font-weight:500;font-size:15px}.PostSetupSocials_content_1KHOS{background:#fff}.PostSetupSocials_toggle_3tnGs{padding:17px 15px;box-shadow:inset 0 -1px 0 #e5e3e3}", ""]);
// Exports
exports.locals = {
	"container": "PostSetupSocials_container_ywaA_",
	"title": "PostSetupSocials_title_38rQQ",
	"content": "PostSetupSocials_content_1KHOS",
	"toggle": "PostSetupSocials_toggle_3tnGs"
};
module.exports = exports;
