<template>
  <div :class="$style.container">
    <h4 :class="$style.title">{{ $t('postOnSocialNetworks') }}</h4>
    <ul :class="$style.content">
      <li v-for="(social, i) of socials" :key="`social-list-${i}`">
        <toggle-input
          v-model="socials[i].enabled"
          :class="$style.toggle"
          :text="social.value"
          :value="social.enabled"
          :active="social.enabled"
          :disabled="social.disabled"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'
import ToggleInput from '@elements/inputs/list/ToggleInput.vue'

export default Vue.extend({
  name: 'PostSetupSocials',
  components: { ToggleInput },
  data() {
    return {
      socials: [
        { value: 'Vkontakte', name: 'vkontakte', disabled: true, enabled: false },
        { value: 'OK.ru', name: 'ok', disabled: true, enabled: false },
        { value: 'Facebook', name: 'facebook', disabled: true, enabled: false },
        { value: 'Twitter', name: 'twitter', disabled: true, enabled: false },
        { value: 'Tumblr', name: 'tumblr', disabled: true, enabled: false },
      ],
    }
  },
})
</script>

<style lang="scss" module>
.container {
  display: grid;
}

.title {
  padding: 17px 15px;
  font-weight: 500;
  font-size: 15px;
}

.content {
  background: white;
}

.toggle {
  padding: 17px 15px;
  box-shadow: inset 0 -1px 0 $secondary-light;
}
</style>
