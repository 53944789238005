// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ToggleInput_item_JtN7_{display:grid;grid-template-columns:1fr auto;-moz-column-gap:20px;column-gap:20px;cursor:pointer;transition:opacity .3s,font-weight .3s}.ToggleInput_item_JtN7_.ToggleInput_disabled_3zTQg{cursor:not-allowed}.ToggleInput_text_2jQ5E{color:var(--supster-secondary);font-weight:400;opacity:.4;transition:opacity .3s}.ToggleInput_text_2jQ5E.ToggleInput_sm_2M39k{font-size:12px;line-height:12px}.ToggleInput_text_2jQ5E.ToggleInput_lg_2LAF7{font-size:15px;line-height:15px}.ToggleInput_text_2jQ5E.ToggleInput_active_358oC{opacity:1}", ""]);
// Exports
exports.locals = {
	"item": "ToggleInput_item_JtN7_",
	"disabled": "ToggleInput_disabled_3zTQg",
	"text": "ToggleInput_text_2jQ5E",
	"sm": "ToggleInput_sm_2M39k",
	"lg": "ToggleInput_lg_2LAF7",
	"active": "ToggleInput_active_358oC"
};
module.exports = exports;
