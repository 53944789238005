// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PostSetupPromotional_container_c86bW{background:#fff}.PostSetupPromotional_promolist-items_ZBJhW{max-height:250px;overflow:hidden;overflow-y:auto}.PostSetupPromotional_promolist-item_QRU3s{padding-left:21px!important}.PostSetupPromotional_promolist-item-active_3uSNJ{pointer-events:none}.PostSetupPromotional_toggle_1khT8{padding:17px 15px;box-shadow:inset 0 -1px 0 #e5e3e3}", ""]);
// Exports
exports.locals = {
	"container": "PostSetupPromotional_container_c86bW",
	"promolist-items": "PostSetupPromotional_promolist-items_ZBJhW",
	"promolist-item": "PostSetupPromotional_promolist-item_QRU3s",
	"promolist-item-active": "PostSetupPromotional_promolist-item-active_3uSNJ",
	"toggle": "PostSetupPromotional_toggle_1khT8"
};
module.exports = exports;
