<template>
  <ul :class="$style.container">
    <li v-for="({ to, disabled, name, key }, index) of settings" :key="`list-${index}`">
      <v-link v-if="to" :to="to" :is-disabled="disabled">
        <span :class="$style.name">{{ name }}</span>
      </v-link>
      <toggle-input
        v-else
        :class="$style.toggle"
        :text="name"
        :value="value[key]"
        :active="value[key]"
        :disabled="disabled"
        @input="$emit('input', { ...value, [key]: $event })"
      />
    </li>
  </ul>
</template>

<script>
import Vue from 'vue'
import VLink from '@elements/VLink.vue'
import ToggleInput from '@elements/inputs/list/ToggleInput.vue'

export default Vue.extend({
  name: 'PostSetupSettings',
  components: { VLink, ToggleInput },
  props: { value: Object },
  data() {
    return {
      settings: [
        { to: '/', name: this.$t('tagPeople'), disabled: true },
        { name: this.$t('enableComments'), key: 'comments' },
      ],
    }
  },
})
</script>

<style lang="scss" module>
.container {
  background: white;
}

.name {
  align-self: center;
  color: $secondary;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  text-decoration: none;
  opacity: 0.6;
  transition: opacity $transition;
}

.toggle {
  padding: 17px 15px;
  box-shadow: inset 0 -1px 0 $secondary-light;
}
</style>
