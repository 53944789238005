<template>
  <div :class="$style.container">
    <v-cover
      color="secondary"
      :class="$style.cover"
      :src="src"
      :mime="fileType"
      :progress="fileProgress"
      :convertation="convertation"
      :radius="20"
      :stroke-width="2"
      :is-remove="false"
      @refresh="onRefresh"
    />
    <v-textarea
      :class="$style.textarea"
      :value="value.body"
      :placeholder="$t('addCaption')"
      @input="$emit('input', { ...value, body: $event })"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VCover from '@layouts/VCover.vue'
import VTextarea from '@elements/VTextarea.vue'

export default Vue.extend({
  name: 'PostSetupHeader',
  components: { VTextarea, VCover },
  props: { value: Object, isEdit: Boolean },
  computed: {
    ...mapGetters('Files', ['filesData', 'files', 'fileLoadParams']),
    ...mapGetters('Post', ['post']),
    file() {
      if (this.files) {
        return this.files[0]
      }
      return null
    },
    fileType() {
      return this.file?.data?.type?.split('/')[0] ?? 'image'
    },
    fileProgress() {
      return this.fileLoadParams(this.file?.id)?.progress ?? 0
    },
    fileError() {
      return !!this.fileLoadParams(this.file?.id)?.error
    },
    src() {
      return this.isEdit ? this.post?.media_objects[0]?.preview_url ?? '' : this.file?.url
    },
    convertation() {
      return this.isEdit ? this.post?.media_objects[0]?.convert_status ?? 0 : 0
    },
  },
  methods: {
    ...mapActions('Media', ['postMedia']),
    ...mapMutations('Files', ['setFileProgress']),
    async onRefresh() {
      this.$emit('refresh')
      await this.postMedia(this.setFileProgress)
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-template-columns: 60px 1fr;
  padding: 6px;
  column-gap: 15px;
}

.cover {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
}

.textarea textarea {
  height: auto;
  background: transparent;
  border: 0;
}
</style>
