<template>
  <div :class="[$style.toggle, { [$style.disabled]: isDisabled }, big && $style.big]">
    <input
      type="checkbox"
      :disabled="isDisabled"
      :class="$style.input"
      :checked="isActive"
      @input="$emit('input', !isActive)"
    />
    <div :class="[$style.knobs, $style[color]]"></div>
    <div :class="$style.layer"></div>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VToggle',
  props: {
    color: {
      default: 'default',
      validator: (v) => ['default', 'primary'].includes(v),
      type: String,
    },
    isActive: Boolean,
    isDisabled: Boolean,
    big: Boolean,
  },
})
</script>
<style lang="scss" module>
.toggle {
  position: relative;
  align-self: center;
  width: 38px;
  height: 18px;

  &.big {
    width: 72px;
    height: 36px;

    .knobs {
      &:before {
        width: 22px;
        height: 22px;
        top: 7px;
        left: 7px;
      }
    }
    input:checked + .knobs:before {
      left: calc(100% - 29px);
    }
  }
}

.input {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;

  &:disabled {
    cursor: not-allowed;
  }
}

.input:active + .knobs:before {
  width: 45%;
  border-radius: 100px;
}

.input:checked:active + .knobs:before {
  margin-left: -15%;
}

.input:checked + .knobs:before {
  left: calc(100% - 15px);
  opacity: 1;
  content: '';
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.layer {
  z-index: 1;
  width: 100%;
  background: $shadow;
  border: 1px solid $secondary-light;
  border-radius: 100px;
  transition: $transition ease all;
}

.knobs {
  z-index: 2;

  &:before {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    opacity: 0.3;
    transition: 0.2s ease all, left 0.45s, cubic-bezier(0.18, 0.89, 0.35, 1.15);
    content: '';
  }

  &.default {
    //
    &:before {
      background: $secondary;
    }
  }

  &.primary {
    //
    &:before {
      background: $primary-light-background;
    }
  }
}
</style>
