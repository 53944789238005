// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PostSetupSettings_container_2lulO{background:#fff}.PostSetupSettings_name_bmj9I{align-self:center;color:var(--supster-secondary);font-weight:400;font-size:15px;line-height:15px;text-decoration:none;opacity:.6;transition:opacity .3s}.PostSetupSettings_toggle_2l3rL{padding:17px 15px;box-shadow:inset 0 -1px 0 #e5e3e3}", ""]);
// Exports
exports.locals = {
	"container": "PostSetupSettings_container_2lulO",
	"name": "PostSetupSettings_name_bmj9I",
	"toggle": "PostSetupSettings_toggle_2l3rL"
};
module.exports = exports;
