// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PostSetupHeader_container_5Q2oL{display:grid;grid-template-columns:60px 1fr;padding:6px;-moz-column-gap:15px;column-gap:15px}.PostSetupHeader_cover_299Zt{width:60px;height:60px;-o-object-fit:cover;object-fit:cover;border-radius:6px}.PostSetupHeader_textarea_8_Jxf textarea{height:auto;background:transparent;border:0}", ""]);
// Exports
exports.locals = {
	"container": "PostSetupHeader_container_5Q2oL",
	"cover": "PostSetupHeader_cover_299Zt",
	"textarea": "PostSetupHeader_textarea_8_Jxf"
};
module.exports = exports;
